import React, { useState, useEffect } from "react";
import { headerItems } from "../../static/constant";
import cultivateLogo from "../../assets/images/header/cultivate_logo.png";
import { Image, Drawer } from "antd";
import "./index.css";
import { motion, useAnimation } from "framer-motion";
import { IoMenu } from "react-icons/io5";
const Header = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 250) {
        if (isHeaderVisible) {
          setIsHeaderVisible(false);
          controls.start({ opacity: 0, y: -100 });
        }
      } else {
        if (!isHeaderVisible) {
          setIsHeaderVisible(true);
          controls.start({ opacity: 1, y: 0 });
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHeaderVisible, controls]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 1, y: 0 }}
        animate={controls}
        //   transition={{ duration: 0.3 }}
        className={`px-24 py-7 w-full justify-between hidden xl:flex mx-auto items-center text-white fixed top-0`}
      >
        {isHeaderVisible && (
          <>
            <div>
              <Image src={cultivateLogo} preview={false} className="" />
            </div>
            <div className="flex gap-16">
              {headerItems.map((index) => (
                <div key={index.key}>
                  <span className="text-[14px] 2xl:text-[16px]">
                    {index.label}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </motion.div>
      {!isHeaderVisible && (
        <div className="fixed top-0 falling-element flex justify-end w-full px-5 xl:px-24 py-3 z-[100]">
          <div
            className="p-3 bg-white rounded-full hover:cursor-pointer hover:scale-105 duration-150"
            onClick={showDrawer}
          >
            <IoMenu className="text-[35px]" />
          </div>
        </div>
      )}
      <Drawer
        onClose={onClose}
        open={openDrawer}
        size={"large"}
        closable={false}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </>
  );
};

export default Header;
