import { Image } from "antd";
import React from "react";
import heroImage from "../../assets/images/content/hero.png";
const Hero = () => {
  return (
    <div className="w-full flex justify-center items-center h-[100vh]">
      <Image
        src={heroImage}
        preview={false}
        className="scale-75 2xl:scale-100"
      />
    </div>
  );
};

export default Hero;
