import React from "react";
import Hero from "./Hero";
import MissionVision from "./MissionVision";

const LandingPage = () => {
  return (
    <div className="flex flex-col">
      <Hero />
      <MissionVision />
    </div>
  );
};

export default LandingPage;
