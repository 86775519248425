import ecosourceLogo from "../assets/images/footer/ecosource.png";
import bsyLogo from "../assets/images/footer/bsy_logo.png";
import sutlaLogo from "../assets/images/footer/sutla_logo.png";
export const headerItems = [
  { key: 1, label: "Home" },
  { key: 2, label: "About Us" },
  { key: 3, label: "Products" },
  { key: 4, label: "Services" },
  { key: 5, label: "Contact Us" },
];
export const footerImages = [
  { key: 1, image: ecosourceLogo },
  { key: 2, image: bsyLogo },
  // { key: 3, image: sutlaLogo },
];
