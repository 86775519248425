import React from "react";
import { footerImages } from "../../static/constant";
import { Image } from "antd";
import "./index.css";
const Footer = () => {
  return (
    <div className="w-full bg-white">
      <div className="flex flex-col xl:grid xl:grid-cols-2 justify-center gap-x-5 gap-y-10 items-center my-10 mx-auto px-5 py-10 xl:max-w-[1240px] 2xl:max-w-[1520px]">
        <div className="w-full flex flex-col">
          <div className="flex gap-3 text-[20px] xl:text-[24px] text-bgDark">
            <span>TEAM.</span>
            <span>CUSTOMER.</span>
            <span>COMMUNITY.</span>
          </div>
          <div className="flex flex-col">
            <span className="text-[40px] md:text-[64px] font-semibold text-bgDark mb-[-30px] disable-selection">
              We Work With
            </span>
            <span className="text-[40px] md:text-[64px] font-semibold text-bgDark disable-selection ">
              The Best Partners
            </span>
          </div>
          <div className="max-w-[500px] w-full text-[16px]">
            <span>
              At EcoSource, our success is built on meaningful partnerships with
              businesses dedicated to the eco-friendly and green movement.
              Collaborating with industry leadersg in sustainable manufacturing,
              and partners in green logistics, we ensure that every aspect of
              our operations aligns with our commitment to environmental
              responsibility. These partnerships go beyond transactions; they
              represent a shared vision for a sustainable future. Together, we
              work tirelessly to offer high-quality eco-friendly products while
              minimizing our collective environmental footprint.
            </span>
          </div>
          <div className="mt-5">
            <button className="px-7 py-3 text-white text-[16px] bg-bgDark">
              Contact Us
            </button>
          </div>
        </div>
        <div className="grid grid-cols-2 justify-center items-center gap-y-10">
          {footerImages.map((item) => (
            <div
              key={item.key}
              // className={`${
              //   item.key === 1 ? "col-span-2" : "col-span-1"
              // } disable-selection items-center flex justify-center`}
              className="disable-selection items-center flex justify-center image-container"
            >
              <Image src={item.image} preview={false} />
            </div>
          ))}
        </div>
        <div className="mt-20">
          <span className="text-[16px]">
            © Copyright <span className="font-bold">2023 CULTIVATE</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
