import "./App.css";
import { Routes, Route } from "react-router-dom";
import IndexLayout from "./components/layout";
import LandingPage from "./components/landing/LandingPage";

function App() {
  const NotAunthenticated = () => {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    );
  };
  return <IndexLayout> {<NotAunthenticated />} </IndexLayout>;
}

export default App;
