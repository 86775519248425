import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { ReactLenis } from "@studio-freight/react-lenis";
import { FloatButton } from "antd";

const IndexLayout = ({ children }) => {
  return (
    <ReactLenis root>
      <div className="flex flex-col justify-center items-center bg-bgDark">
        <Header />
        <div className="w-full">
          <div>{children}</div>
        </div>
        <Footer />
        <FloatButton.BackTop />
      </div>
    </ReactLenis>
  );
};

export default IndexLayout;
